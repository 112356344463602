import { computed, onMounted, reactive } from '@vue/composition-api';

export function useAgGrid({ root }) {
  const state = reactive({
    searchQuery: '',
    gridOptions: {},
    maxPageNumbers: 7,
    gridApi: null,
    defaultColDef: {
      sortable: true,
      editable: false,
      resizable: true,
      suppressMenu: true,
    },
  });
  const paginationPageSize = computed(() => {
    if (state.gridApi) return state.gridApi.paginationGetPageSize();
    else return 50;
  });
  const totalPages = computed(() => {
    if (state.gridApi) return state.gridApi.paginationGetTotalPages();
    else return 0;
  });

  const currentPage = computed({
    get() {
      if (state.gridApi) return state.gridApi.paginationGetCurrentPage() + 1;
      else return 1;
    },
    set(val) {
      state.gridApi.paginationGoToPage(val - 1);
    },
  });

  const updateSearchQuery = (val) => {
    state.gridApi.setQuickFilter(val);
  };

  const onGridReady = () => {
    state.gridApi = state.gridOptions.api;
    if (state.gridApi && root.$store.state.windowWidth > 768) {
      state.gridApi.sizeColumnsToFit();
    }
  };

  onMounted(() => {
    // state.gridOptions.context = {
    //   componentParent: getCurrentInstance(),
    // };
  });

  return {
    state,
    paginationPageSize,
    updateSearchQuery,
    totalPages,
    onGridReady,
    currentPage,
  };
}
