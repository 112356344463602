<template>
  <div>
    <vx-card>
      <div class="flex justify-between items-center">
        <vs-input
          class="mb-4 w-full md:mb-0 mr-4"
          v-model="searchQuery"
          placeholder="Buscar inventario"
        />
        <div>
          <vs-button
            :to="{ name: 'inventario-crear-producto' }"
            class="mb-4 md:mb-0"
            >Crear</vs-button
          >
        </div>
      </div>
      <div v-if="!inventoryLoading && !loading">
        <!-- Prompt Verify -->
        <ValidationObserver ref="observerStatus">
          <vs-prompt
            class=""
            :active.sync="inventoryAmountTogglePrompt"
            title="Cambiar cantidades de producto"
            :buttonsHidden="true"
            v-if="currentProduct"
          >
            <section class="mb-4 mt-1">
              <div class="mt-3 flex gap-2 items-end">
                <ValidationProvider
                  name="Precio"
                  rules="required|min_value:0"
                  v-slot="{ errors }"
                >
                  <vs-input
                    class="w-full"
                    type="number"
                    label="Precio"
                    v-model="inventoryProduct.price"
                  ></vs-input>
                  <div class="text-danger text-sm leading-tight">
                    {{ errors[0] }}
                  </div>
                </ValidationProvider>
                <div>
                  <vs-button @click="changeProductPrice(currentProduct)">
                    Aplicar
                  </vs-button>
                </div>
              </div>
              <div class="mt-3 flex gap-2 items-end">
                <ValidationProvider
                  name="Stock"
                  type="number"
                  rules="required|min_value:0"
                  v-slot="{ errors }"
                  class="w-full"
                >
                  <vs-input
                    class="w-full"
                    label="Stock"
                    v-model="inventoryProduct.stock"
                  ></vs-input>
                  <div class="text-danger text-sm leading-tight">
                    {{ errors[0] }}
                  </div>
                </ValidationProvider>
                <div>
                  <vs-button @click="changeProductStock(currentProduct)">
                    Aplicar
                  </vs-button>
                </div>
              </div>
            </section>
            <vs-table
              v-if="currentProduct.history"
              stripe
              :data="currentProduct.history"
              max-items="3"
              pagination
            >
              <template slot="header">
                <h3>Historial</h3>
              </template>
              <template slot="thead">
                <vs-th> # </vs-th>
                <vs-th> Tipo </vs-th>
                <vs-th> Responsable </vs-th>
                <vs-th> Observación </vs-th>
                <vs-th> Fecha </vs-th>
              </template>

              <template slot-scope="{ data }">
                <vs-tr :key="indexTr" v-for="(tr, indexTr) in data">
                  <vs-td class="whitespace-no-wrap" :data="data[indexTr].id">
                    {{ data[indexTr].id }}
                  </vs-td>

                  <vs-td class="whitespace-no-wrap" :data="data[indexTr].type">
                    {{ data[indexTr].type | inventoryTypes }}
                  </vs-td>

                  <vs-td
                    class="whitespace-no-wrap"
                    :data="data[indexTr].first_name"
                  >
                    {{ data[indexTr].creatorUser.first_name }}
                    {{ data[indexTr].creatorUser.last_name }}
                  </vs-td>

                  <vs-td
                    class="whitespace-no-wrap"
                    :data="data[indexTr].description"
                  >
                    {{ data[indexTr].description }}
                  </vs-td>

                  <vs-td
                    class="whitespace-no-wrap"
                    :data="data[indexTr].status"
                  >
                    {{ data[indexTr].created_at }}
                  </vs-td>
                </vs-tr>
              </template>
            </vs-table>
          </vs-prompt>
        </ValidationObserver>

        <ag-grid-vue
          ref="agGridTable"
          :gridOptions="agGridState.gridOptions"
          class="ag-theme-material w-100 my-4 ag-grid-table"
          :columnDefs="columnDefs"
          :defaultColDef="agGridState.defaultColDef"
          :rowData="inventory"
          rowSelection="multiple"
          colResizeDefault="shift"
          :animateRows="true"
          :floatingFilter="false"
          :pagination="true"
          :paginationPageSize="paginationPageSize"
          :suppressPaginationPanel="true"
          :enableRtl="$vs.rtl"
          :overlayLoadingTemplate="overlayLoadingTemplate"
        >
        </ag-grid-vue>
        <vs-pagination
          :total="totalPages"
          :max="agGridState.maxPageNumbers"
          v-model="currentPage"
        />
      </div>
      <div v-else class="h-64 my-24 flex justify-center items-center">
        <p class="text-grey">Cargando...</p>
      </div>
    </vx-card>
  </div>
</template>

<script>
import { useAgGrid } from '@/composable/useAgGrid.js';
import { INVENTORY } from '@/graphql/queries.js';
import {
  computed,
  defineComponent,
  reactive,
  ref,
  toRefs,
  watch,
} from '@vue/composition-api';
import { useQuery, useResult } from '@vue/apollo-composable';
import CellRendererAmount from '@/components/cell-renderer/CellRendererAmount.vue';
import CellRendererPercentAmount from '@/components/cell-renderer/CellRendererPercentAmount.vue';
import CellRendererInventarioEdit from './cell-renderer/CellRendererInventarioEdit.vue';
import CellRendererInventoryAmount from './cell-renderer/CellRendererInventoryAmount.vue';

import {
  UPDATE_INVENTORY_PRICE,
  UPDATE_INVENTORY_STOCK,
} from '@/graphql/mutations';

export default defineComponent({
  components: {
    CellRendererAmount,
    CellRendererInventarioEdit,
    CellRendererPercentAmount,
    CellRendererInventoryAmount,
  },
  setup(_, { root }) {
    const state = reactive({
      columnDefs: [
        {
          headerName: 'ID',
          field: 'id',
          width: 100,
          filter: true,
        },
        {
          headerName: 'Editar',
          field: 'editar',
          filter: true,
          width: 90,
          cellRendererFramework: 'CellRendererInventarioEdit',
        },
        {
          headerName: 'Cantidad',
          field: 'amount',
          filter: true,
          width: 120,
          cellRendererFramework: 'CellRendererInventoryAmount',
        },
        {
          headerName: 'Nombre',
          field: 'name',
          width: 300,
          filter: true,
        },
        {
          headerName: 'Descripción',
          field: 'description',
          width: 300,
          filter: true,
        },
        {
          headerName: 'Precio',
          field: 'price',
          filter: true,
          width: 100,
          cellRendererFramework: 'CellRendererAmount',
        },
        {
          headerName: 'Stock',
          field: 'stock',
          filter: true,
          width: 100,
        },
        {
          headerName: 'Creado por',
          field: 'creatorUser.first_name',
          width: 150,
          filter: true,
        },
        {
          headerName: 'Compañia',
          field: 'company.name',
          width: 150,
          filter: true,
        },
        {
          headerName: 'Creación',
          field: 'created_at',
          width: 200,
          filter: true,
        },
        {
          headerName: 'Actualización',
          field: 'updated_at',
          width: 200,
          filter: true,
        },
      ],
      loading: false,
      overlayLoadingTemplate:
        '<span>Please wait while your rows are loading</span>',
      inventoryAmountTogglePrompt: false,
      currentProduct: null,
      inventoryProduct: {
        price: null,
        stock: null,
      },
    });

    const currentPage = ref(1);
    const first = ref(20);
    const searchQuery = ref('');
    const paginationPageSize = ref('');

    const {
      result: inventoryResult,
      loading: inventoryLoading,
      refetch: refetchInventory,
    } = useQuery(INVENTORY, {
      page: currentPage,
      first: first,
      search: searchQuery,
    });

    watch(inventoryLoading, (value) => {
      state.loading = value;
    });

    const inventory = useResult(
      inventoryResult,
      [],
      (data) => data.inventory.data
    );

    const paginatorInfo = useResult(inventoryResult, [], (data) => ({
      total: data.inventory.paginatorInfo.total,
      perPage: data.inventory.paginatorInfo.perPage,
    }));

    const totalPages = computed(() =>
      Math.ceil(paginatorInfo.value.total / paginatorInfo.value.perPage)
    );

    const { state: agGridState } = useAgGrid({ root });

    const changeInventory = (product) => {
      console.log('changeInventory');
      state.inventoryAmountTogglePrompt = true;
      state.currentProduct = product;
      state.inventoryProduct = {
        price: product.price,
        stock: product.stock,
      };
    };

    const changeProductPrice = (inventory) => {
      state.loading = true;
      root.$apollo
        .mutate({
          mutation: UPDATE_INVENTORY_PRICE,
          variables: {
            input: {
              inventory_id: inventory.id,
              price: state.inventoryProduct.price,
            },
          },
        })
        .then(() => {
          refetchInventory();
          state.loading = false;
          root.$vs.notify({
            color: 'success',
            title: '¡Inventario actualizado!',
            text: 'Se actualizó el precio del producto correctamente.',
          });
        })
        .catch((error) => {
          state.loading = false;
          root.$vs.notify({
            color: 'danger',
            title: 'Inventario no actualizado',
            text: `No se pudo actualizar el precio. ${error?.message}`,
          });
          console.error('❌', error);
        });
    };

    const changeProductStock = (inventory) => {
      state.loading = true;
      root.$apollo
        .mutate({
          mutation: UPDATE_INVENTORY_STOCK,
          variables: {
            input: {
              inventory_id: inventory.id,
              stock: state.inventoryProduct.stock,
            },
          },
        })
        .then(() => {
          refetchInventory();
          root.$vs.notify({
            color: 'success',
            title: '¡Inventario actualizado!',
            text: 'Se actualizó el stock del producto correctamente.',
          });
          state.loading = false;
        })
        .catch((error) => {
          state.loading = false;
          root.$vs.notify({
            color: 'danger',
            title: 'Inventario no actualizado',
            text: `No se pudo actualizar el stock. ${error?.message}`,
          });
          console.error('❌', error);
        });
    };

    return {
      ...toRefs(state),
      paginationPageSize,
      agGridState: {
        ...agGridState,
        gridOptions: {
          ...agGridState.gridOptions,
          context: {
            changeInventory: changeInventory,
          },
        },
      },
      totalPages,
      currentPage,
      inventory,
      searchQuery,
      inventoryLoading,
      changeProductStock,
      changeProductPrice,
    };
  },
});
</script>
