<template>
  <div>{{ params.value | currencyFormat }}</div>
</template>

<script>
export default {
  name: 'CellRendererAmount',
};
</script>

<style lang="scss" scpoped></style>
