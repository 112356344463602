<template>
  <div>{{ params.value | percentFormat }}</div>
</template>

<script>
export default {
  name: 'CellRendererPercentAmount',
};
</script>

<style lang="scss" scpoped></style>
