<template>
  <div class="flex justify-center items-center h-full">
    <feather-icon
      icon="PlusCircleIcon"
      svgClasses="h-5 w-5 mr-5 hover:text-primary cursor-pointer"
      @click="editAmount"
    />
  </div>
</template>

<script>
export default {
  name: 'CellRendererInventoryAmount',
  methods: {
    editAmount() {
      this.params.context.changeInventory(this.params.data);
    },
  },
};
</script>
